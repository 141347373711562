import {Video} from '../../models/video';
import './video-card.css';
import {LeafletMap} from '../leaflet-map/leaflet-map';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {MoreOutlined} from '@ant-design/icons';
import {Dropdown} from 'antd';
import Menu from 'antd/lib/menu';

export interface VideoCardProps {
    idx: number;
    video: Video;
    removeVideo: (idx: number) => void;
}

export const VideoCard = (props: VideoCardProps) => {
    let navigate = useNavigate();

    function openVideoPage() {
        if (props.video.id)
            navigate(`/gallery/${props.video.id}`);
    }

    let menu = <Menu>
        <Menu.Item>
            <span onClick={() => {
                navigator.clipboard.writeText('https://vid-app.netlify.app/gallery/' + props.video.id);
            }}>Copy link</span>
        </Menu.Item>
        <Menu.Item>
            <span className={'trash-icon'} onClick={() => props.removeVideo(props.idx)}>Remove</span>
        </Menu.Item>
    </Menu>;

    return (
        <div className={'card col'}>
            <div className={'flx ctr-cross-axis spc'}>
                    <div className={'col flx2 ctr'} onClick={() => openVideoPage()}>
                        <h4>{moment(props.video.createdAt).format('DD/MM/YY HH:mm')}</h4>
                        <h4>{moment(props.video.createdAt, 'DD/MM/YY HH:mm').fromNow()}</h4>
                    </div>

                    <Dropdown trigger={['click']} key={''} overlay={menu}
                              className={'more-btn ant-dropdown-link'}>
                        <MoreOutlined/>
                    </Dropdown>

            </div>

            <div className={'ctr col content'}>
                <div className={'vid'}>
                    <video className={'vid'}
                           src={props.video.linkUrl ?? window.URL.createObjectURL(props.video.blob)}
                           loop
                           controls
                    />
                </div>
                {props.video.location && <LeafletMap coords={props.video.location}/>}
            </div>
        </div>
    );
};
