import * as localforage from 'localforage';

const KEY: string = 'VIDEOS';
const LOGIN_KEY: string = 'LOGIN_KEY';

class CacheService {
    files: any = [];

    async saveFile(value) {
        let videos: any[] = (await this.getFiles() as any[]) ?? [];
        videos.push(value);
        await localforage.setItem(KEY, videos);
    }

    async setFiles(videos) {
        await localforage.setItem(KEY, videos);
    }

    getFiles() {
        return localforage.getItem(KEY);
    }

    setLoginStatus(isLogged) {
        localforage.setItem(LOGIN_KEY, isLogged ? 1 : 0);
    }

    async getLoginStatus() {
        let result = await localforage.getItem(LOGIN_KEY);
        return !!Number(result ?? 0);
    }

    resetValues() {
        localforage.clear();
    }
}

let cache = new CacheService();

export default cache;
