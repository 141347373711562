import {LeafletMap} from '../../components/leaflet-map/leaflet-map';
import videosService from '../../services/video.service';
import {useParams} from 'react-router-dom';
import {Video} from '../../models/video';
import {FaChevronLeft} from 'react-icons/fa';
import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';

export interface VideoPageProps {
    videoId?: string;
    video?: Video;
}

export const VideoPage = (props: VideoPageProps) => {
        const [video, setVideo] = useState(null);
        const [videoId, setVideoId] = useState(null);
        const params = useParams();

        useEffect(() => {
            setVideoId(props.videoId ?? (params as any).id);
            if (videoId) {
                videosService.fetchVideo(videoId).then((result) => {
                    setVideo(result.data.data);
                });
            }

        }, [props.videoId, videoId, params]);

        return (videoId ?
                (!video ? <Loader
                                type={'BallTriangle'}
                                height={100}
                                width={100}
                                color={'grey'}
                            />
                        : <div className={'col mt-'}>
                            <div className={'flx ctr-cross-axis spc'}>
                                <h3 className={'mt-5 text-center'}>{video.name ?? video.createdAt}</h3>
                                <a className={'home-btn'} href="/"><FaChevronLeft/></a>
                            </div>

                            <div className={'ctr col content'}>
                                <div className={'vid'}>
                                    <video className={'vid'}
                                           src={video.linkUrl ?? window.URL.createObjectURL(video.blob)}
                                           loop
                                           controls
                                    />
                                </div>
                                {video?.location && <LeafletMap coords={video?.location}/>}
                            </div>
                        </div>
                )
                : (<h2>Video not found. Or you dont have access to that video</h2>)

        );
    }
;
