import fire from '../assets/config/fire';
import {createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import axios from 'axios';
import cache from './files.service';
import videoService from './video.service';
import {Video} from '../models/video';
import appStore from '../store/app.store';

const SERVER_URL = 'https://vidz-app.herokuapp.com';
// const SERVER_URL = 'http://localhost:8000';

class ProfileService {
    async loginUser(email, pwd) {
        cache.setLoginStatus(1);
        const auth = getAuth(fire);
        return await signInWithEmailAndPassword(auth, email, pwd);
    }

    async registerUser(email, pwd) {
        cache.setLoginStatus(1);
        const auth = getAuth(fire);
        return await createUserWithEmailAndPassword(auth, email, pwd);
    }

    async fetchProfile(firebaseId: string) {
        let profile = await axios.get(`${SERVER_URL}/profiles`, {params: {firebaseId}});
        let cachedVideos: Video[] = await cache.getFiles() as Video[];
        if (profile?.data?.data?.id) {
            await videoService.saveVideos(cachedVideos, profile.data.data.id);
            cache.resetValues();
        }

        return profile;
    }

    async createProfile(firebaseId: string, email: string) {
        let profile =  await axios.post(`${SERVER_URL}/profiles`, {firebaseId, email});
        let cachedVideos: Video[] = await cache.getFiles() as Video[];
        if (profile?.data?.data?.id) {
            await videoService.saveVideos(cachedVideos, profile.data.data.id);
            cache.resetValues();
        }

        return profile;
    }

    async logout() {
        cache.setLoginStatus(0);
        appStore.setProfile(null);
        const auth = getAuth(fire);
        return await auth.signOut();
    }
}

let profileService = new ProfileService();

export default profileService;
