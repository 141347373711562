import VideoRecorder from 'react-video-recorder';
import {MsgDialog} from '../../components/dialog';
import {useEffect, useState} from 'react';
import {Video} from '../../models/video';
import './recording.page.css';
import Menu from 'antd/lib/menu';
import {UserOutlined} from '@ant-design/icons';
import {Dropdown} from 'antd';
import videosService from '../../services/video.service';
import AppStore from '../../store/app.store';
import {toJS} from 'mobx';
import profileService from '../../services/profile.service';
import Loader from 'react-loader-spinner';
import {v4 as uuidv4} from 'uuid';
import {IoLogOutOutline} from 'react-icons/all';

export const RecordingPage = (props: { fbId: string, store: typeof AppStore }) => {
    const [dialogData, setDialogData] = useState(null);
    const [startPos, setStartPos] = useState(null);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (!navigator.geolocation)
            setDialogData({title: 'error', msg: 'You need to enable location services'});

        if (!navigator.mediaDevices)
            setDialogData({title: 'error', msg: 'You need to enable camera'});

    }, []);


    let recordStarted = () => {
        navigator.geolocation.getCurrentPosition((e) => setStartPos({lat: e.coords.latitude, lon: e.coords.longitude}));
    };

    let recordCompleted = async (blob) => {
        setShowLoader(true);
        const d = new Date();
        const createdAt = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' +
            d.getHours() + ':' + d.getMinutes();

        const video: Video = {
            id: uuidv4(),
            createdAt,
            file: new File([blob], createdAt, {
                lastModified: d.getTime(),
                type: blob.type
            }),
            blob: blob,
            location: startPos
        };
        const profile = props.store.profile;
        await videosService.saveVideo(video, profile?.id);
        setShowLoader(false);
    };

    const closeDialog = () => setDialogData(null);
    const showCameraError = (cameraE) => {
        console.log({cameraE});
        setDialogData('Access to camera is block');
    };
    let menuNoUser = <Menu>
        <Menu.Item key={1}>
            <a href="/login"> Login </a>
        </Menu.Item>
        <Menu.Item key={2}>
            <a href="/register"> Register </a>
        </Menu.Item>
    </Menu>;

    async function logout() {
        await profileService.logout();
    }

    let menu = <Menu>
        <Menu.Item key={3}>
            <button onClick={() => logout()}> Logout</button>
        </Menu.Item>
    </Menu>;
    return (
        <div className={'full'}>
            <Dropdown trigger={['click']} key={''} overlay={toJS(props.fbId) ? menu : menuNoUser}
                      className={'menu-btn ant-dropdown-link'}>
                {toJS(props.fbId) ? <IoLogOutOutline/> : <UserOutlined/>}
            </Dropdown>
            <VideoRecorder
                isOnInitially={true}
                isFlipped={false}
                countdownTime={0}
                onStartRecording={recordStarted}
                onRecordingComplete={recordCompleted}
                onError={showCameraError}
                constraints={{audio: true, video: {facingMode: {ideal: 'environment'}}}}
            />
            {showLoader && <Loader
                type={'BallTriangle'}
                height={100}
                width={100}
                color={'grey'}/>}
            <a className={'gallery-btn'} href="/gallery">Gallery</a>
            {!!dialogData &&
                <MsgDialog data={dialogData}
                           showModal={!!dialogData}
                           handleOk={() => closeDialog()}
                           handleCancel={closeDialog}
                />
            }
        </div>
    );
};
