import cache from '../../services/files.service';
import React, {useEffect, useState} from 'react';
import {VideoCard} from '../../components/video-card/video-card';
import './gallery.page.css';
import {FaChevronLeft} from 'react-icons/fa';
import videoService from '../../services/video.service';
import videosService from '../../services/video.service';
import {observer} from 'mobx-react';
import Loader from 'react-loader-spinner';

export const GalleryPage = observer((props: { fbId: string, videos: any }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [videos, setVideos] = useState([]);


    useEffect(() => {
        setShowLoader(true);
        cache.getLoginStatus().then(isLoggedIn => {
            if (isLoggedIn && props.fbId) {
                videosService.fetchVideos(props.fbId).then((res: any) => {
                    setVideos(res.data.data);
                    setShowLoader(false);
                });
            } else if (!isLoggedIn) {
                videosService.fetchVideos().then((res: any) => {
                    setVideos(res);
                    setShowLoader(false);
                });
            }
        });
    }, [props.fbId]);

    function removeVideo(idx: number) {
        setShowLoader(true);
        const video = videos.filter((video, index) => idx === index)[0];
        const newVideos = videos.filter((video, index) => idx !== index);
        if (video.id) {
            videoService.deleteVideo(video.id).then(() => {
                setShowLoader(false);
            });
        } else {
            cache.setFiles(newVideos).then(() => {
                setShowLoader(false);
            });
        }

        setVideos(newVideos);
    }


    return (<div className={'full col'}>
        <h2 className={'ctr m-2'}>Recent Videos</h2>
        <a className={'home-btn'} href="/"><FaChevronLeft/></a>
        <div className={'wrap even'}>
            {showLoader
                ? <Loader
                    type={'BallTriangle'}
                    height={100}
                    width={100}
                    color={'grey'}
                    visible={showLoader}
                />
                : videos?.length ? videos.map((vid, index) => {
                    return <VideoCard key={index} video={vid} removeVideo={removeVideo} idx={index}/>;
                }) : <h5>No Videos yet</h5>}
        </div>
    </div>);
});
