import {Video} from '../models/video';
import axios from 'axios';
import appStore from '../store/app.store';
import cache from './files.service';

const SERVER_URL = 'https://vidz-app.herokuapp.com';
//const SERVER_URL = 'http://localhost:8000';

class VideosService {
    async saveVideos(videos: Video[], profileId: string) {
        let result = null;
        if (videos && profileId) {
            let promises: Promise<any>[] = [];
            videos.forEach(vid => {
                promises.push(this.uploadVideo(vid, profileId));
            });
            result = await Promise.allSettled(promises);

            console.log({result});
        }
        return !!result;
    }

    async saveVideo(video: Video, profileId: any) {
        let newVideo = video;
        debugger;
        if (!profileId)
            await cache.saveFile(video);
        else {
            let formData = new FormData();
            formData.append('id', video.id);
            formData.append('video', video.file);
            formData.append('profileId', profileId);
            formData.append('location', JSON.stringify(video.location));

            let response = await axios.post(`${SERVER_URL}/videos/upload`, formData);
            newVideo = response.data.data;
        }
        appStore.addVideo(newVideo);
    }

    async fetchVideo(videoId: string) {
        return await axios.get(`${SERVER_URL}/videos/${videoId}`);
    }

    async fetchVideos(fbId?: string) {
        if (fbId)
            return await axios.post(`${SERVER_URL}/videos`, {fbId});
        else
            return cache.getFiles();
    }

    async deleteVideo(videoId: string) {
        return await axios.delete(`${SERVER_URL}/videos/${videoId}`);
    }

    private async uploadVideo(video: Video, profileId: any) {
        let formData = new FormData();
        formData.append('video', video.file);
        formData.append('profileId', profileId);
        formData.append('location', JSON.stringify(video.location));

        return axios.post(`${SERVER_URL}/videos/upload`, formData);
    }
}

let videosService = new VideosService();

export default videosService;
